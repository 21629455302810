var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gallery-item"},[_c('div',{staticClass:"gallery-description"},[_c('span',{staticClass:"nums"},[_c('span',{staticClass:"datetime"},[_vm._v(_vm._s(_vm._f("dateSafe")(_vm.paragraph.pdate)))]),_c('span',{staticClass:"count"},[_c('a',{staticClass:"counter secondary--text chip",attrs:{"target":"_blank","href":'/' +
            _vm.api.querystring_stringify({
              groups: 'none',
              q: `(${_vm.api.get_group(_vm.paragraph)})`,
            })}},[_vm._v(_vm._s(_vm.paragraph.count || _vm.paragraph.images.length))])]),_c('v-btn',{staticClass:"orig no-underline",attrs:{"href":_vm.paragraph.source.url,"target":"_blank","icon":""}},[_c('v-icon',[_vm._v("mdi-web")])],1)],1),_c('a',{staticClass:"force-text break-anywhere",attrs:{"href":'/' +
        _vm.api.querystring_stringify({
          q: `source(\`${_vm.paragraph.source.url || _vm.paragraph.source.file || ''}\`)`,
        }),"target":"_blank"}},[_vm._v(_vm._s(_vm.paragraph.source.url))]),_c('p',{staticClass:"content",on:{"click":_vm.search_selection}},[_vm._v(_vm._s(_vm.text))]),_vm._l((_vm.tags),function(tag){return [(tag != '...')?_c('a',{key:`${_vm.paragraph._id}-${Math.random()}-${tag}`,class:['tag', 'chip', _vm.tag_class(tag)],attrs:{"alt":tag,"href":'/' +
          _vm.api.querystring_stringify({
            groups: tag.match(/^#/) ? 'none' : tag.match(/^@/) ? 'group' : '',
            sort: '-pdate',
            q: tag.match(/^[@#]/)
              ? _vm.quote(tag)
              : _vm.quote(tag) + ',' + _vm.api.scope(_vm.paragraph),
          }),"target":"_blank"}},[_vm._v(_vm._s(tag))]):_c('v-btn',{key:`${_vm.paragraph._id}-${Math.random()}-${tag}`,attrs:{"icon":""},on:{"click":function($event){_vm.show_all_tags = true}}},[_c('v-icon',[_vm._v("mdi-more")])],1)]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }