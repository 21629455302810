var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(!_vm.viewer)?_c('v-navigation-drawer',{attrs:{"app":"","disable-resize-watcher":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$router.push('/')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("search")))])],1),_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tasks")))])]},proxy:true}],null,false,224619653)},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$router.push('/tasks')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("all")))])],1),_vm._l((_vm.shortcuts || [{ _id: '', name: _vm.$t('quick-task') }]),function(s){return _c('v-list-item',{key:s._id,attrs:{"link":""},on:{"click":function($event){return _vm.$router.push('/tasks/shortcut/' + s._id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(s.name))])],1)})],2),_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("user")))])]},proxy:true}],null,false,4269343434)},[_vm._l(([
            ['preferences', 'preferences'],
            ['history', 'history'],
          ]),function(item){return _c('v-list-item',{key:item[0],attrs:{"link":""},on:{"click":function($event){return _vm.$router.push(`/${item[1]}`)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item[0])))])],1)}),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.log_out();
            _vm.$router.push('/login');}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("log-out")))])],1)],2),_c('v-list-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.admin),expression:"admin"}],scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("admin")))])]},proxy:true}],null,false,4012197972)},_vm._l(([
            ['file-storage', 'storage'],
            ['dataset', 'datasets'],
            ['console', 'dbconsole'],
            ['system-admin', 'admin'],
            ['auto-tagging', 'autotags'],
            ['shortcuts', 'shortcuts'],
          ]),function(item){return _c('v-list-item',{key:item[0],attrs:{"link":""},on:{"click":function($event){return _vm.$router.push(`/${item[1]}`)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item[0])))])],1)}),1)],1)],1):_vm._e(),(!_vm.viewer)?_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.api.meta.app_title))]),_c('v-spacer'),_c('queue-view',{attrs:{"data":_vm.queue}})],1):_vm._e(),_c('v-main',[_c('v-progress-linear',{staticStyle:{"{\n          opacity":"0"},attrs:{"indeterminate":"","id":"loadingBar","app":"","fixed":""}}),_c('div',{attrs:{"id":_vm.viewer ? 'viewer' : 'content-wrapper'}},[_c('keep-alive',{attrs:{"include":['SearchForm', 'ResultsView', 'DbConsole']}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1),_c('div',{staticStyle:{"height":"40px"}}),(!_vm.viewer)?[_c('event-console',{attrs:{"enabled":!this.viewer},on:{"queue":_vm.update_queue}}),_c('v-footer',{attrs:{"id":"footer"}},[_c('div',{staticClass:"language mr-5"},[_c('v-select',{style:({
              width: '150px',
            }),attrs:{"dense":"","flat":"","single-line":"","prepend-icon":"mdi-web","items":Object.entries(_vm.$i18n.messages).map((pair) => ({
                value: pair[0],
                text: pair[1]._lang,
              }))},model:{value:(_vm.ui_language),callback:function ($$v) {_vm.ui_language=$$v},expression:"ui_language"}})],1),_c('div',{staticClass:"powered-by"},[_vm._v(" Powered by Jindai © 2018-"+_vm._s(new Date().getFullYear())+" zhuth & contributors ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.api.meta.copyright)}})])]:_vm._e()],2),_c('input',{attrs:{"type":"hidden","name":"","id":"testing-code"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }