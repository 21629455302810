var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.active_paragraph)?_c('v-sheet',{directives:[{name:"touch",rawName:"v-touch",value:({
    left: () => _vm._event_handler('left'),
    right: () => _vm._event_handler('right'),
    down: () => _vm.do_close(),
    up: () => _vm.$emit('rating', { inc: 1 }),
  }),expression:"{\n    left: () => _event_handler('left'),\n    right: () => _event_handler('right'),\n    down: () => do_close(),\n    up: () => $emit('rating', { inc: 1 }),\n  }"}],style:(_vm.view_mode == 'gallery' ? { overflow: 'hidden', height: '100%' } : {}),on:{"wheel":_vm._wheel_handler}},[(_vm.view_mode == 'file')?_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"heading"},[_c('h3',[_vm._v(_vm._s(_vm.file)+"  ")])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","enabled":_vm.page > 0},on:{"click":function($event){return _vm._event_handler('right')}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-text-field',{staticStyle:{"max-width":"50px"},attrs:{"dense":"","flat":"","type":"number","value":_vm.page},on:{"input":_vm.try_page}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm._event_handler('left')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1):_c('v-btn',{staticClass:"ma-10",attrs:{"icon":"","fab":"","fixed":"","top":"","right":""},on:{"click":function($event){return _vm.do_close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),(_vm.view_mode == 'gallery')?[_c('div',{staticClass:"browser"},[(_vm.active_item && ['video', 'audio'].includes(_vm.active_item.item_type)
  )?_c('video-player',{ref:"videoPlayer",staticClass:"video-player",attrs:{"src":_vm.api.get_item_video(_vm.active_item),"options":{
          muted: false,
          autoplay: true,
          style: {
            width: '100%',
            height: '100vh',
            overflow: 'hidden',
            top: 0,
            left: 0,
          },
        }}}):_c('image-player',{ref:"imagePlayer",staticClass:"image-player",attrs:{"src":_vm.active_item
          ? _vm.api.get_item_image(_vm.active_item)
          : (_vm._event_handler('continue'), ''),"fit":_vm.api.config.fit}}),_c('div',{staticClass:"browsing description"},[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('ol',{directives:[{name:"show",rawName:"v-show",value:(_vm.active_paragraph && _vm.active_paragraph_images.length > 1),expression:"active_paragraph && active_paragraph_images.length > 1"}],ref:"thumbnails"},_vm._l((_vm.active_paragraph_images),function(thumbnail){return _c('li',{key:thumbnail._id,class:{ selected: _vm.active_item._id == thumbnail._id }},[_c('img',{attrs:{"src":_vm.api.get_item_image(thumbnail),"alt":""}})])}),0),_c('v-pagination',{attrs:{"length":3,"total-visible":0},on:{"previous":function($event){_vm.browsing_page = 2;
              _vm._event_handler('arrowleft');},"next":function($event){_vm.browsing_page = 2;
_vm._event_handler('arrowright');}},model:{value:(_vm.browsing_page),callback:function ($$v) {_vm.browsing_page=$$v},expression:"browsing_page"}})],1),(_vm.active_item)?_c('v-col',{staticClass:"item-description",attrs:{"cols":"10"}},[_c('v-row',{staticClass:"mt-3 mb-3"},[(typeof _vm.active_item.rating == 'number')?_c('div',{staticClass:"mr-3"},[_c('v-rating',{staticStyle:{"display":"inline-block"},attrs:{"background-color":"white","color":"yellow accent-4","half-increments":"","hover":"","size":"18"},on:{"input":function($event){return _vm.$emit('rating', { val: $event })}},model:{value:(_vm.active_item.rating),callback:function ($$v) {_vm.$set(_vm.active_item, "rating", $$v)},expression:"active_item.rating"}}),_c('span',{staticClass:"grey--text text--lighten-2"},[_vm._v("("+_vm._s(_vm.active_item.rating.toFixed(1))+") ")])],1):_vm._e(),_c('div',[_vm._l((_vm.plugin_pages.filter(
                    (x) => x.format
                  )),function(filter,page_name){return _c('v-btn',{key:page_name,staticClass:"t_func sim",attrs:{"icon":"","dense":"","href":'/' +
  _vm.api.querystring_stringify({
    q:
      _vm.api.scope(_vm.active_paragraph) +
      `;plugin(${_vm.format(filter.format, {
        mediaitem: _vm.active_item,
        paragraph: _vm.active_paragraph,
      })});`,
  }),"target":"_blank"}},[_c('v-icon',[_vm._v(_vm._s(filter.icon))])],1)}),_c('v-btn',{attrs:{"icon":"","dense":"","target":"_blank","data-keybind":"i"},on:{"click":function($event){return _vm.$emit('info', _vm.active_item)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)],2)]),_c('GalleryContentView',{staticClass:"browsing-content",attrs:{"paragraph":_vm.active_paragraph,"view_mode":"gallery-description"}})],1):_vm._e()],1)],1)],1)]:(_vm.active_paragraph && _vm.active_paragraph.source)?[(_vm.image_type == 'pdf')?_c('v-row',[_c('vue-pdf-embed',{attrs:{"source":_vm.page_image}})],1):_vm._e(),_c('v-row',{staticClass:"main"},[_c('div',{staticClass:"paragraphs"},[_c('div',{staticClass:"original-html",domProps:{"innerHTML":_vm._s(_vm.html)}}),_vm._l((_vm.shown_paragraphs),function(p){return _c('div',{key:p._id},[_c('ContentView',{attrs:{"paragraph":p,"item_width":"100%","view_mode":_vm.view_mode}})],1)}),(_vm.active_paragraph)?_c('div',{staticClass:"mt-5 meta"},[_vm._v(" "+_vm._s(_vm.$t("date"))+": "+_vm._s(_vm._f("dateSafe")(_vm.active_paragraph.pdate))),_c('br'),_vm._v(" "+_vm._s(_vm.$t("pagenum"))+": "+_vm._s(_vm.active_paragraph.pagenum)+" "),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.edit_pagenum}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-form-textbox")])],1),_c('br'),_vm._v(" "+_vm._s(_vm.$t("outline"))+": "+_vm._s(_vm.active_paragraph.outline)),_c('br'),_vm._v(" "+_vm._s(_vm.$t("source"))+": "),(_vm.active_paragraph.source.url)?_c('a',{attrs:{"href":_vm.active_paragraph.source.url,"target":"_blank"}},[_vm._v(_vm._s(_vm.active_paragraph.source.url))]):_vm._e(),_vm._v(" "+_vm._s(_vm.active_paragraph.source.file)+" "+_vm._s(_vm.active_paragraph.source.page)),_c('br')],1):_vm._e()],2),(_vm.image_type != 'pdf')?_c('div',{staticClass:"image",on:{"click":function($event){_vm.show_modal = _vm.image_type != 'pdf' && !!_vm.page_image}}},[_c('img',{attrs:{"src":_vm.page_image,"alt":""},on:{"load":function($event){$event.target.style.height =
            _vm.window_height - $event.target.offsetTop - 20 + 'px'}}})]):_vm._e()])]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }