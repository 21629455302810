var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fabs"},[_c('v-badge',{attrs:{"bordered":"","color":"green darken-2","content":'' + _vm.selection_count,"value":_vm.selection_count > 0,"overlap":""}},[_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.$emit('toggle-selection')},"dblclick":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-select-all")])],1)],1),_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm._emit('interactive-tagging')}}},[_c('v-icon',[_vm._v("mdi-tag")])],1),_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm._emit('rating', { inc: 0.5 })}}},[_c('v-icon',[_vm._v("mdi-heart")])],1),_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm._emit('group', { advanced: true, del: false })}}},[_c('v-icon',[_vm._v("mdi-group")])],1),_c('v-menu',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}])},[_c('v-list',{staticClass:"pointer"},[_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){return _vm.$emit('toggle-fits')}}},[_vm._v(" "+_vm._s(_vm.$t("fit-" + _vm.api.next_fit())))])],1),_vm._l(([
          { name: 'play', event: 'play' },
          'merge',
          'split',
          'reset-storage',
          { name: 'task', call: 'send_task' },
          { name: 'delete', icon: 'mdi-delete', call: 'delete' },
        ].map((x) => (typeof x === 'object' ? x : { name: x, call: x }))),function(item){return _c('v-list-item',{key:item.name},[_c('v-list-item-title',{on:{"click":function($event){item.call ? _vm._emit(item.call) : _vm.$emit(item.event)}}},[(item.icon)?_c('v-icon',[_vm._v(_vm._s(item.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(item.name)))],1)],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }